<div
  class="relative mx-auto block w-full rounded-lg bg-white px-8 lg:px-14 py-8 lg:py-14 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">

  <button (click)="drawerService.close()"
          class="absolute top-1 right-1 inline-flex rounded-md p-1.5 text-gray-500 hover:text-100ciels-300 focus:outline-none focus:ring-1 focus:ring-100ciels-300 focus:ring-offset-2 focus:ring-offset-100ciels-100"
          type="button">
    <span class="sr-only">Dismiss</span>
    <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
      <path
        d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
    </svg>
  </button>

  <!--Center title -->
  <h1 class="font-bold text-3xl md:text-4xl leading-tight text-100ciels-300 uppercase text-center">
    {{ 'claimGift' | translate }}
  </h1>
  <!-- Description -->
  <p class="my-4 text-gray-500 font-semibold uppercase text-center whitespace-pre-line">
    {{ 'claimGiftDescription' | translate }}
  </p>


  <div>
    <div class="relative mt-2 rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg class="h-12 w-12 block m-auto object-cover" viewBox="0 0 60 60">
          <defs>
            <style>.cls-claim {
              fill: none;
              stroke: #6b6b6b;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.76px;
            }</style>
          </defs>
          <path class="cls-claim fill-none stroke-2"
                d="m24.72,30.69h-12.31c-1.46,0-2.64-1.18-2.64-2.64v-3.52c0-1.46,1.18-2.64,2.64-2.64h35.18c1.46,0,2.64,1.18,2.64,2.64v3.52c0,1.46-1.18,2.64-2.64,2.64h-12.31m0-5.28v10.55l-5.28-1.76-5.28,1.76v-14.07m22.87,13.19v12.31c0,1.46-1.18,2.64-2.64,2.64H15.05c-1.46,0-2.64-1.18-2.64-2.64v-16.71m24.62-11.05c7.01-3.93,6.07-2.98,2.75-8.55-2.18-3.65-5.76-.1-8.9,4.62m-10.55,4.07c-3.99-2.06-2.95-2.04-.24-6.17,2.21-3.36,5.94.39,9.06,4.86"/>
        </svg>
      </div>
    </div>
  </div>
</div>
