import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { emulateClick } from '../core/a11y';
import { DrawerService } from '../drawer/drawer.service';
import { NotificationService } from '../notification/notification.service';
import { CenterService } from '../shared/services/center.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-claim-gift',
  imports: [
    NgxTolgeeModule
  ],
  templateUrl: './claim-gift.component.html'
})
export class ClaimGiftComponent {
  readonly emulateClick = emulateClick;
  centerService = inject(CenterService);
  drawerService = inject(DrawerService);
  notificationService = inject(NotificationService);
}
